.forgotPassword__form__title {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.forgotPassword__form__bottom {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.forgotPassword__form__bottom__label {
  color: #181c32;
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
}

.forgotPassword__form__body {
  padding: 62px 229.5px 44px 229.5px;

  @media only screen and (max-width: 768px) {
    padding: 47px 21.5px 50px 21.5px;
  }
}

.resetPassword__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  min-height: 580px;
  height: fit-content;

  border-radius: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 768px) {
    height: fit-content;
    margin-top: 35px;
  }
}

.reset_pw_footer {
  align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}