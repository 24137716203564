.progressBarM__form {
  display: none;
  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    flex-flow: row;
    border-bottom: 1px solid #eef2f5;
    padding: 25px 30px;
  }
}
