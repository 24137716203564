.qrCode__body {
  padding-top: 30px;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    margin: 0px;
  }
}

.qrCode__body__point {
  display: flex;
  flex-flow: row;
  max-width: 604px;

  @media only screen and (max-width: 768px) {
    max-width: auto;
    flex-flow: column;
  }
}

.qrCode__body__point__1,
.qrCode__body__point__2 {
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef2f5;
  height: 33px;
  width: 33px;
  margin-right: 13px;
  margin-bottom: 20px;
}

.qrCode__body__point__1__txt,
.qrCode__body__point__2__txt {
  font-weight: 400;
  font-size: 16px;
}

.qrCode__img {
  margin-left: 30px;
  margin-bottom: 15px;

  @media only screen and (max-width: 768px) {
    margin-left: -10px;
    margin-top: 15px;
  }
}

.qrCode__verifyInput {
  margin-left: 40px;
  margin-top: 15px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}
