@import '../../styles/variables';
// src/dropdown_banner/DropdownBanner.scss

.dropdown-banner:not(:last-child) {
  margin-bottom: 25px;
}

// title
.dropdown-banner__banner__t-wrap {
  display: flex;
  align-items: center;
}
.dropdown-banner__banner__t-wrap__title {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 22px;
  color: #000000;
  margin-left: 15px;
}

// indicator
.dropdown-banner__banner__t-wrap__indicator {
  width: 18px;
  height: 18px;
  border-radius: 30px;
}

.dropdown-banner__banner__t-wrap__indicator {
  &--active {
    background-color: $successColor
  }
  &--upcoming {
    background-color: $warningColor;
  }
  &--closed {
    background-color: $errorColor;
  }
}
