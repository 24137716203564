.file-viewer-modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 50px;
  overflow-y: scroll;
}

.file-viewer-modal__content__iframe,
.file-viewer-modal__content__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file-viewer-modal__content__header {
  display: flex;
  justify-content: flex-end;
}

.file-viewer-modal__content__close-btn {
  top: 15px;
  right: 10px;
  background: none;
  border: none;
  color: #000;
  position: absolute;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
