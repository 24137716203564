.delete-file-modal {
  padding: 89px 73px 84.5px 73px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.delete-file-modal__warning-icon {
  width: 65px;
  margin-bottom: 18.5px;
}

.delete-file-modal__title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #3f4254;
}
