@import './variables.scss';

// modal overlay
.shared-modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

// modal
.shared-modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  display: flex;
  max-width: 50%;
  justify-content: center;
  left: 25%;

  @media screen and (max-width: 768px) {
    padding: 0px;
    left: 0px;
    right: 0px;
    bottom: 45px;
    max-width: 100%;
  }
}

.shared-btn-thicker-border {
  border: 2px solid $primaryColor;
}

.shared-sidebar-icon {
  min-width: 24px;
  max-width: 24px;
}
.shared-mobile-hide {
  display: none !important;
}

.shared-mobile-pg-title {
  color: $primaryColor !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.shared-dropdown {
  border-radius: 6px;
  border: 1px solid #3f4254;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 45px;
  padding-left: 15px;
}
