@import '../../../../styles/variables.scss';

.authMethodLogin__nextBtn {
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  height: 51px;
  border-radius: 6px;
  background-color: $primaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
