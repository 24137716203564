@import '../../../../styles/variables.scss';

.CreatePassword {
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.createPassword__form {
  min-height: 710px;
  height: auto !important;
}

.createPassword__form__body {
}

// USER INFO SECTION
.createPassword__form__body__userInfoContainer {
  display: flex;
  flex-flow: column;

  padding: 22px 39px;

  border: 1px solid $primaryColor;
  border-radius: 12px;

  margin: 41px 31px 0px 31px;
}

.createPassword__form__body__userInfo__greetingTxt {
  font-weight: 600;
  color: #3f4254;
  font-size: 24px;
  margin-bottom: 12px;
}

.createPassword__form__body__phoneInfoContainer {
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
}

.createPassword__form__body__phoneInfo__label {
  font-weight: 500;
  color: #3f4254;
  font-size: 18px;
  margin-right: 21px;
}

.createPassword__form__body__phoneInfo__value {
  font-weight: 600;
  color: #3f4254;
  font-size: 18px;
}

// PASSWORD SECTION

.createPassword__form__body__passwordContainer {
  margin-top: 39px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 81px;

  @media only screen and (max-width: 768px) {
    padding: 0px 24px 0px 24px;
    margin-bottom: 36px;
  }
}

.createPassword__form__body__password__label {
  font-weight: 600;
  color: #181c32;
  font-size: 26px;
  margin-bottom: 31px;
}

// VALIDATION

.createPassword__form__body__validationContainer {
  display: flex;
  flex-flow: column;
  margin-top: 24px;
}

.createPassword__form__body__validation__title {
}

.createPassword__form__body__password__confirmInput {
  margin-top: 25px;
}

.createPassword__form__body__validationWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 11px;
}

.createPassword__form__body__validation__icon {
  margin-right: 11px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.createPassword__form__body__validation__title {
  color: $errorColor;
  font-size: 14px;
  font-weight: 400;
}
.createPassword__form__body__validation__title--green {
  color: $successColor;
}
.createPassword__form__body__validation__successTxt {
  color: $successColor;
  font-size: 14px;
  font-weight: 400;
}

.createPassword__form__body__validation__errorTxt {
  color: $errorColor;
  font-size: 14px;
  font-weight: 400;
}

.createPassword__form__body__password__matchErrWrap {
  margin-top: 20px;
}
