@import '../../../styles/variables.scss';

.common__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  min-height: 580px;
  height: fit-content;

  border-radius: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.common__link {
  color: $primaryColor;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
}

.common__fw500 {
  font-weight: 500;
}

.common__primaryTxtColor {
  color: $primaryColor;
}

.common__nextBtn {
  width: 82px;
  height: 51px;
  border-radius: 6px;
  background-color: $primaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.common__loginBtn {
  width: 377px;
  height: 51px;
  border-radius: 6px;
  background-color: $primaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }
}

.common__radioBtnDefault {
  min-width: 20px !important;
  min-height: 20px !important;

  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 50%;
}

.common__radioBtnActive {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #ecf0f3;
  border-radius: 50%;
  border: 5px solid $primaryColor;
}

.common__radioBtnContainer {
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.common__disabledBtn {
  opacity: 0.5;

  &:hover {
    opacity: 0.5 !important;
  }
}
