@import '../../../../../theme/variables.ems';

.AdminSidebar,
.AdminSidebar--spanish,
.AdminSidebar--french {
  width: 240px;
  background-color: #fff;
  height: 100vh;
  z-index: 999;

  position: fixed;
  top: 0;
  z-index: 3;

  @media only screen and (max-width: 820px) {
    display: none;
  }
}

.AdminSidebar--spanish,
.AdminSidebar--french {
  @media only screen and (max-width: 965px) {
    display: none;
  }
}

.adminSidebar__logo__container {
  display: flex;
  flex-direction: column;
}

.adminSidebar__logo {
  padding: 18px 20px !important;
  width: 230px;
  display: inline-flex;
  padding: 1px 12px;
}

.adminSidebar__adminLabel {
  border-radius: 12px;
  border: 1px solid $emsPrimaryColor;
  background: #fff;
  align-self: flex-end;
  margin-right: 32px;
  display: inline-flex;
  padding: 1px 12px;
  margin-bottom: 15px;
  margin-right: 32px;
  // text
  color: $emsPrimaryColor;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AdminSidebar__divider {
  border-top: 2px solid #e1e1ee;
  opacity: 1;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: 0px;
  margin-bottom: 19px;
}

.adminSideBar__tabActiveWrap,
.adminSideBar__tabActiveWrap--Mexico,
.adminSideBar__tabActiveWrap--PES {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  background-color: $emsPrimaryLightColor;
  margin-bottom: 13px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.adminSideBar__tabInactiveWrap {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  margin-bottom: 13px;
  padding-right: 5px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.adminSideBar__tabActiveTxt,
.adminSideBar__tabActiveTxt--Mexico,
.adminSideBar__tabActiveTxt--PES {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #7c3242;
}

// MEXICO --
.adminSideBar__tabActiveWrap--Mexico {
  background-color: $emsPrimaryLightColor;
}

.adminSideBar__tabActiveTxt--Mexico {
  color: $emsPrimaryColor;
}

.adminSideBar__tabActiveTxt {
  color: $emsPrimaryColor;
}

.adminSideBar__tabInactiveTxt {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #3f4254;
}

.AdminSidebar--french {
  width: 245px;
}
