.eligibleElectorsList__rowItem--id,
.eligibleElectorsList__columnLabel--id {
  width: 7%;
  max-width: 7%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--firstName,
.eligibleElectorsList__columnLabel--firstName {
  width: 9%;
  max-width: 9%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--middleName,
.eligibleElectorsList__columnLabel--middleName {
  width: 9%;
  max-width: 9%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--lastName,
.eligibleElectorsList__columnLabel--lastName {
  width: 9%;
  max-width: 9%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--district,
.eligibleElectorsList__columnLabel--district {
  width: 20%;
  max-width: 20%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--addr,
.eligibleElectorsList__columnLabel--addr {
  width: 20%;
  max-width: 20%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--channel,
.eligibleElectorsList__columnLabel--channel {
  width: 10%;
  max-width: 10%;
  word-wrap: break-word;
}

.electorListTable__rowItem--dob,
.eligibleElectorsList__columnLabel--dob {
  width: 5%;
  max-width: 5%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--voted,
.eligibleElectorsList__columnLabel--voted {
  width: 8%;
  max-width: 8%;
  word-wrap: break-word;
}

.eligibleElectorsList__tableHeader--french {
  padding: 25px;
}
