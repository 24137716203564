@import '../../../../theme/variables.ems';

.SuperAdmin__HelpAndSupport {
  @media only screen and (max-width: 1370px) {
    margin-top: 140px;
  }
}

.helpSupport__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-right: 140px;
  margin-left: 20px;
}

.helpAndSupportM__title {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $emsPrimaryColor;
    margin-top: 125px;
    text-align: center;
    margin-bottom: -100px;
  }
}

.helpSupport__card {
  width: 349px;
  min-height: 180px;
  border-radius: 12px;
  background-color: #fff;
  margin-right: 24px;
  margin-bottom: 31px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
}

.helpSupport__body {
  padding: 28px 76px 22px 33px;
}

.helpSupport__footer {
  padding: 14px 33px 20px 25px;
  border-top: 2px solid #eef2f5;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.helpSupport__body__txt {
  font-size: 20px;
  color: #3f4254;
  font-weight: 600;
}

.helpSupport__footer__elipsisEdit {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.helpSupport__header__btn {
  border-color: #3f4254 !important;
  width: fit-content;
}
.helpSupport__header__btn__txt {
  color: #3f4254 !important;
  margin-left: 10px;
}

.helpSupport__download,
.helpSupport__download--admin {
  font-weight: 600;
  font-size: 16px;
  background-color: #3e4253;
  color: #fff;
  border-radius: 6px;

  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: #fff;
  }
}

.helpSupport__downloadWrap--admin {
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: -25px !important;
}
