@import '../../../../../../theme/variables.ems';

.common__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  min-height: 580px;
  height: fit-content;

  border-radius: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 768px) {
    margin-top: 35px;
  }
}

.common__link {
  color: $emsPrimaryColor;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
}

.common__primaryTxtColor {
  color: $emsPrimaryColor;
}

.common__nextBtn {
  width: 82px;
  height: 51px;
  border-radius: 6px;
  background-color: $emsPrimaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.disabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }
}
