.voterDecisionModal--approve,
.voterDecisionModal--decline,
.voterDecisionModal--declineSuccess,
.voterDecisionModal--declineReason {
  height: 480px;
}

.voterDecisionModal--declineReason {
  height: fit-content;
}

.voterDecisionModal--approve__body,
.voterDecisionModal--decline__body,
.voterDecisionModal--declineSuccess__body,
.voterDecisionModal--declineReason__body {
  padding: 65px 115px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.voterDecisionModal--declineSuccess__body {
  padding: 83px 63px 37px 63px;
}

.voterDecisionModal--declineReason__body {
  padding: 29px 37px 29px 29px;
}

.voterDecisionModal--declineReason__body__input--reason {
  width: 100%;
}
.voterDecisionModal--approve__body__icon,
.voterDecisionModal--decline__body__icon,
.voterDecisionModal--declineSuccess__body__icon {
  width: 75px;
  margin-bottom: 40px;
}

.voterDecisionModal--approve__body__txt,
.voterDecisionModal--decline__body__txt,
.voterDecisionModal--declineSuccess__body__txt {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.voterDecisionModal--declineReason__body__input--desc {
  margin-top: 41px;
}
