@import '../../../../theme/variables.ems';

.voterNavbar__rightContainer {
  display: flex;
  align-items: center;
}

.voterNavbar__right__dropdown {
  margin-right: 63px;
}

.voter__navbar {
  height: 100px;
  padding-top: 30px;
  padding-bottom: 23px;
  padding-left: 45px;
  padding-right: 82px;
  background-color: #fff;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding: 41px 16px 18px 16px;
  }
}

.voter__navbar__logo {
  width: 254px;
  @media only screen and (max-width: 768px) {
    width: 174px;
  }
}

.voter__navbarBackLink {
  color: #6c7289;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.VoterNavbarMobile {
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
  }

  width: auto;
  flex-flow: row;

  justify-content: space-between;

  padding-top: 41px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 25px;
}

.voterNavbarMobile__logo {
  width: 137.22px;
}

.voterNavbar__loginBtn {
  display: flex;
  align-items: center;
  margin-left: 46px;
}

.voterNavbar__loginBtn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.voterNavbar__loginBtn__icon {
  margin-right: 15px;
  width: 30px;
}

.voterNavbar__loginBtn__label {
  font-size: 21px;
  font-weight: 500;
  color: $emsPrimaryColor;
}
