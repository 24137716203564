@import '../../../../styles/variables.scss';

.authVerify__form,
.authVerify__form--spanishOnboarding,
.authVerify__form--spanishLoginFlow {
  height: 100% !important;
  max-height: 580px;

  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;

  border-radius: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 768px) {
    margin-top: 35px;
    margin-left: 16px;
    margin-right: 16px;
    max-height: auto !important;
    max-height: 570px;
  }
}
.authVerify__form {
  max-height: 620px;

  @media only screen and (max-width: 768px) {
    max-height: 660px;
  }
}

.authVerify__form--spanishLoginFlow {
  max-width: 1100px !important;
  @media only screen and (max-width: 768px) {
    max-height: 660px;
  }

  @media only screen and (max-height: 450px) {
    height: fit-content !important;
  }
}

.authVerify__form--spanishOnboarding {
  max-width: 1100px !important;
  max-height: 600px;
  @media only screen and (max-width: 768px) {
    max-height: 710px;
  }
}

.authVerify__form--loginFlow {
  height: 100% !important;
  max-height: 600px;
  position: absolute;
  margin-top: 89px;
  max-width: 830px;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  @media only screen and (max-width: 768px) {
    height: 100% !important;
    max-height: 610px;
    margin-top: 35px;
  }

  @media only screen and (max-height: 450px) {
    height: fit-content !important;
  }
}

.authVerify__form__actionBar--loginFlow {
  // border-top: 1px solid #eef2f5;
  padding: 27px 30px 30px 30px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    border-top: 1px solid #eef2f5;
  }
}

.authVerify__form__body {
  padding: 40px 180px 36px 180px;

  @media only screen and (max-width: 768px) {
    padding: 34px 21.5px 34px 21.5px;
  }
}

.authVerify__form__body--loginFlow {
  padding: 65px 180px 36px 180px;

  @media only screen and (max-width: 768px) {
    padding: 53px 30px 34px 30px;
  }
}

.authVerify__form__body__subtitle {
  font-weight: 500;
  font-size: 18px;
}

.authVerify__invalidCode {
  display: flex;

  width: 100%;
  justify-content: center;
  align-items: flex-start;
  // padding-right: 100px;
  // padding-left: 100px;
}

.authVerify__invalidCode__icon {
  width: 18px;
}

.authVerify__invalidCode__txt {
  font-size: 14px;
  color: $errorColor;
  margin-left: 8px;
}
