@import '../../../../../theme/variables.ems';

.AdminTopbar,
.AdminTopbar--spanish {
  height: 65px;
  width: 100%;
  padding-left: 240px;
  padding-right: 41px;
  background-color: #fff;

  position: fixed;
  top: 0;
  z-index: 2;

  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1370px) {
    margin-left: 110px;
    padding: 20px;
    height: auto;
    flex-flow: column;
  }
  @media only screen and (max-width: 820px) {
    display: none;
  }
}

.AdminTopbar--spanish {
  @media only screen and (max-width: 965px) {
    display: none;
  }
}

.adminTopbar__btnWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 1370px) {
    flex-flow: row;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0;
  }
}

.adminTopbar__currentTimeTxt {
  font-size: 16px;
  font-weight: 400;
  margin-left: 31px;
}

.adminTopbar__sessionTime {
  margin-right: 30px;
}

.adminTopbar__sessionTime,
.adminTopbar__sessionTimeMobile {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.adminTopbar__sessionTimeMobile {
  width: 100%;
  margin-right: 50px;
}

.adminTopbar__sessionTime__label {
  font-size: 16px;
  font-weight: 400;
  color: #6c7289;
}

.adminTopbar__sessionTime__value,
.adminTopbar__sessionTime__minTxt,
.adminTopbar__sessionTime__secTxt {
  font-weight: 700;
  font-size: 16px;
  color: $emsPrimaryColor;
}

.adminTopbar__sessionTime__minTxt,
.adminTopbar__sessionTime__secTxt {
  font-weight: 400;
}

.adminTopbar__sessionTime__minTxt {
  margin-left: 5px;
  margin-right: 5px;
}

.adminTopbar__sessionTime__secTxt {
  margin-left: 5px;
}

.adminTopbar__sessionTimeWrap {
  display: flex;
  flex-flow: row;
}

.adminTopbar__logoutTxt,
.adminTopbar__logoutTxt--MEXICO {
  font-weight: 600;
  font-size: 16px;
  color: #3699ff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.adminTopbar__logoutTxt--MEXICO {
  color: $emsPrimaryColor;
}

.adminTopbar__divider--MEXICO {
  margin-right: 30px;
  margin-left: 32px;
}

.fw-400 {
  font-weight: 400;
}
