.event-attachments {
  margin-top: 25px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}

.event-attachments__header {
  display: flex;
  flex-flow: row;
}

.event-attachments__header__title-and-search {
  display: flex;
}

.event-attachments__header__title-and-search__title-label {
  font-size: 20px;
  font-weight: 500;
  margin-right: 30px;
  margin-left: 25px;
  color: #3f4254;
}
.event-attachments__header__btns {
  display: flex;
  align-items: center;
}
.event-attachments__header__btns__btn {
  height: 46px;

  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  border: 2px solid #3f4254;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.event-attachments__header__btns__btn--add-link {
  margin-right: 25px;
}

.event-attachments__header__btns__btn__label {
  font-size: 15px;
  font-weight: 600;
  color: #3f4254;
  margin-left: 10px;
}
