@import '../../../styles/variables.scss';

.common__pg {
  margin-left: 240px;
  margin-top: 65px;
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.common__pg__header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  padding-top: 28px;
  padding-left: 25px;
  margin-right: 95px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.common__pg__header__title {
  font-weight: 500;
  font-size: 26px;
  color: $primaryColor;
  margin-right: 25px;
}

.common__pg__header__title--mobile {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $primaryColor;
  }
}

.common__flex__row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.commonHeaderM__title,
.commonHeaderM__title--spanish {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $primaryColor;
    margin-top: 125px;
    text-align: center;
    margin-bottom: -100px;
  }
}

.commonHeaderM__title--spanish {
  margin-top: 35px;
}

@mixin common__flex__row {
  display: flex;
  flex-flow: row;
}

@mixin common__flex__row__center {
  display: flex;
  flex-flow: row;
  align-items: center;
}

@mixin common__flex__column {
  display: flex;
  flex-flow: column;
}

@mixin common__flex__column__center {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@mixin common__flex__fullCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

$INE__pink: $primaryColor;

.common__backBtn {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin-right: 27px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.common__fw400 {
  font-weight: 400;
}

.common__mobileHide {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
