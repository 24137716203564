.viewIdModal__viewOuter {
  display: flex;
  flex-flow: column;
}

.viewIdModal__view {
  padding: 22px 30px 30px 30px;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.viewIdModal__view__photoId {
  width: 100% !important;
}

.ModalFooter {
  padding: 25px 20px 25px 30px;
  border-top: 1px solid #eef2f5;
  display: flex;
  justify-content: flex-end;
}
