.EditUserModal {
  padding: 20px;
}

.EditUserModal__error {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding: 50px 100px;
  display: flex;
}

.EditUserModal__error__txt {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
.EditUserModal__error__icon {
  width: 60px;
}
