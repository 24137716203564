.Calendar {
  display: flex;
  flex-flow: column;
  padding-left: 265px;
  margin-top: 32px;
  margin-right: 45px;
}
.fc {
  background-color: #fff;
  padding: 21px 17px;
  border-radius: 12px;
}

:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: #fff;
  --fc-neutral-text-color: #fff;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #0065a4;
  --fc-button-border-color: #0065a4;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #034c7a;
  --fc-button-active-bg-color: #034c7a;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #0065a4;
  --fc-event-border-color: #0065a4;
  --fc-event-text-color: #090808;
  --fc-event-selected-overlay-color: #034c7a;

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}

.fc-col-header-cell-cushion {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}

.fc-toolbar-title {
  font-weight: 400;
}

.fc-daygrid-day-number {
  color: #000;
  font-size: 15px;
}

.fc-dayGridMonth-button,
.fc-button-primary {
  margin-right: 3.28px !important;

  border: none !important;
  outline: none !important;
  :focus {
    outline: none;
  }
}

.fc-button-active-border {
  border: none;
}

.fc-event-title {
  color: #fff;
  font-size: 12px;
  margin-left: 5px;
}
