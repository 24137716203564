@import '../../../../../theme/variables.ems';

.adminDropdown__lang__txt,
.adminDropdown__option__txt {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3f4254;
}

.adminDropdown__options--languages {
  width: 120px;
}

.adminDropdown__optionWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // margin-bottom: 10px;
  padding: 7px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #f1faff;
    border-radius: 4px;

    .dropdown__iconOptionTxt {
      color: #3699ff;
    }

    .dropdown__iconOptionImg path {
      fill: #3699ff;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.dropdown__txtWrap--mexicoAdminUser {
  // width: 100% !important;
  width: 230px !important;
  padding: 10px;
  right: 100px;
  position: relative;

  background: none;
  box-shadow: none;
}

.editDelete__dropdownOptions {
  width: 187px;

  margin-left: 100px;
  margin-top: 40px;
}

.dropdown__iconTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // margin-left: 10px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: $emsPrimaryLightColor;
    border-radius: 4px;

    .dropdown__iconTxt {
      color: $emsPrimaryColor;
    }

    .adminDropdown__downloadIcon {
      color: $emsPrimaryColor;
    }

    .adminDropdown__trashIcon {
      color: $emsPrimaryColor;
    }
  }
}
.dropdown__iconTxt {
  font-size: 16px;
  font-weight: 400;
  color: #6c7289;
  margin-left: 8px;
}

.dropdown__icon__option {
  padding: 7px;
}

.adminDropdown__mexicoAdminUser {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.adminDropdown__mexicoAdminUser__option {
  width: 240px;
  background-color: #fff;
  padding: 7px;
  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);
  border-radius: 4px;
  position: absolute;
  margin-left: -100px;
  z-index: 9999999;
}

.adminDropdown__mexicoDistricts__option,
.adminDropdown__mexicoChannel__option {
  width: 260px;
  background-color: #fff;
  padding: 7px;
  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);
  border-radius: 4px;
}

.createPostModal_optionTxt {
  // margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;
}

.dropdown__iconOptionWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // margin-bottom: 10px;
  padding: 7px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #e7f5fd;
    border-radius: 4px;

    .dropdown__iconOptionTxt {
      color: #3699ff !important;
    }

    .dropdown__iconOptionImg path {
      fill: #3699ff;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}
