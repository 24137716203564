.SuperAdmin__Reports {
  margin-left: 240px;
  margin-top: 65px;
  margin-right: 95px;
}

.reports__body {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;

  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  min-height: 194px;
  margin-top: 29px;
  margin-left: 25px;
  padding: 44px 53px 38px 45px;
}

.reports__body__card__seperator {
  margin-top: 23px;
  margin-bottom: 23px;
  border-width: 1px;
  border-style: dashed;
  border-color: #e2e6ea;
}

.reports__body__card {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  width: 100%;
}

.reports__body__card__txt {
  font-weight: 500;
  font-size: 16px;
}
