.flex-row {
  display: flex;
  flex-flow: row;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
}

.flex-row-center {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.flex-full-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.flex-row-self-end {
  display: flex;
  align-self: flex-end;
}
