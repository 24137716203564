.AdminNavbarMobile,
.AdminNavbarMobile--spanish {
  display: none !important;
  height: 100px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  width: 100%;
  position: fixed;
}

.adminNavMobile__logo {
  width: 137.22px;
  margin-left: 19.78px;
  margin-bottom: 16px;
}

.adminNavMobile__hamburgerMenu {
  width: 36px;
  height: 36px;
  margin-bottom: 18px;
}

.adminNavMobile__contentWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 18px;
}

@media only screen and (max-width: 820px) {
  .AdminNavbarMobile {
    display: flex !important;
    z-index: 2;
  }
}

.AdminNavbarMobile--spanish {
  @media only screen and (max-width: 965px) {
    display: flex !important;
    z-index: 2;
  }
}
