@import '../../styles/variables.scss';

.EventDisplayCard {
  width: 575px;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  margin-right: 30px;
  margin-bottom: 25px;

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
  }

  .eventDisplayCard__body {
    padding: 30px 36px 20.6px 25px;
    display: flex;
    flex-flow: row;
    align-items: flex-start;

    @media only screen and (max-width: 768px) {
      flex-flow: column;
    }

    &__content {
      margin-left: 35px;
      display: flex;
      flex-flow: column;

      @media only screen and (max-width: 768px) {
        margin-top: 11px;
        margin-left: 0px;
      }

      &__label {
        font-size: 18px;
        font-weight: 400;
      }

      &__title {
        margin-top: 11px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 24px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 95%;
      }

      &__dividerWrap {
        margin-top: 25px;
        margin-bottom: 28px;
      }

      &__divider {
        color: #a1a5b7;
        font-weight: 400;
        font-size: 18px;
        margin-right: 28px;
      }

      &__vtClosed {
        &__label {
          font-weight: 400;
          font-size: 14px;
          color: #6c7289;
        }

        &__value {   
          margin-top:5px;
          font-size: 16px;
          color: $primaryColor;
          font-weight: 700;

          @media only screen and (max-width: 768px) {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .eventDisplayCard__footer {
    border-top: 1px solid #e1e1ee;
    padding: 22px 25px 18px 25px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
      padding: 10px 38px 13px 0px;
    }

    &__link {
      color: $primaryColor;
      font-size: 16px;
      font-weight: 500;
      border-radius: 6px;
      border: 2px solid $primaryColor;
      padding: 7px 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// handle overflowing
.eventDisplayCard__body__content {
  max-width: 80%;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}
