@import '../../../../styles/variables.scss';

.phoneVerify__form,
.phoneVerify__form--Spanish {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  height: 580px;

  border-radius: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 768px) {
    height: auto;
    margin-top: 35px;
  }
}

.phoneVerify__form__body {
  padding: 46px 100px 61px 100px;

  @media only screen and (max-width: 768px) {
    padding: 25px 33px 24px 33px;
  }
}

.phoneVerify__form--Spanish {
  max-width: fit-content;
  height: fit-content;
}

.phoneVerify__form__progBar__stepContainer--Spanish {
  display: flex;
  margin-right: 15px;
}

.phoneVerify__inputsWrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-left: 20px;
  }
}

// PROGRESS BAR -------------------
.phoneVerify__form__progBar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 1px solid #eef2f5;

  padding: 25px 30px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.phoneVerify__form__progBar__stepContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
}
// ACTIVE STEP
.phoneVerify__form__progBar__stepTxtWrap--active,
.phoneVerify__form__progBar__stepTxtWrap--default {
  background-color: $primaryColor;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phoneVerify__form__progBar__stepLabel--active,
.phoneVerify__form__progBar__stepLabel--default {
  font-weight: 500;
  font-size: 16px;
  color: $primaryColor;
  margin-left: 10px;
}

// DEFAULT STEP
.phoneVerify__form__progBar__stepTxtWrap--default {
  background-color: #a1a5b7 !important;
}

.phoneVerify__form__progBar__stepLabel--default {
  color: #a1a5b7 !important;
}

.phoneVerify__form__progBar__stepTxt {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.phoneVerify__formFooter__disclaimerTxt {
  font-weight: 400;
  font-size: 16px;
  color: #5e6278;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.phoneVerify__formFooter__disclaimerLink {
  color: $primaryColor;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}

.phoneVerify__form__actionBar {
  border-top: 1px solid #eef2f5;
  padding: 27px 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
}

.phoneVerify__form__actionBar--loginFlow {
  // border-top: 1px solid #eef2f5;
  padding: 27px 30px 30px 30px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    border-top: 1px solid #eef2f5;
  }
}

.phoneVerify__primaryPhoneTxt {
  color: $primaryColor;
}
