.column-width-25 {
  width: 25%;
}
.column-width-15 {
  width: 15%;
}
.column-width-10 {
  width: 10%;
}
.column-width-5 {
  width: 5%;
}

.column-width-5--adminUserDropdown {
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
}
