.AddDocument {
  padding: 25px 28px 42px 25px;
}

.addDocument__upload {
  height: 50px;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.addDocument__upload__txt {
  margin-left: 14px;
  font-weight: 500;
  font-size: 14px;
}

.addDocument__upload__iconWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.addDocument__changeDoc__upload {
  height: 87px;
  &:hover {
    cursor: auto;
    opacity: 1;
  }
}

.addDocument__changeDoc {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.help_cancelBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: #6c7289;
  background-color: #eef2f5;
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
