.CustomDropdownContainer {
  display: flex;
  flex-flow: column;
}

.CustomDropdown {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  background: #fff;

  border-radius: 6px;
  border: 1px solid #000;

  color: #3f4254;

  width: 100%;
  min-width: 250px;
  max-width: 250px;
  height: 35px;

  &:hover {
    cursor: pointer;
    // opacity: 0.8;
  }

  &:focus {
    outline: none;
    border: 2px solid #000;
    box-shadow: 0px 0px 7px 3px rgba(54, 153, 255, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.customDropdown__toggle {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  padding-left: 17px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 250px; /* Sets a maximum width for the toggle to force text overflow */
  overflow: hidden; /* Hides overflow */
}

$customMarginTop: 220px;

.customDropdown__toggle__options {
  z-index: 1;
  position: absolute;

  background-color: #fff;
  padding: 7px;
  width: 250px;
  border-radius: 4px;

  max-height: 250px;
  overflow-y: scroll;

  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);

  &.customDropdown__toggle__options--many {
    margin-top: 220px; // Adjust the value as needed
  }
}

.customDropdown__toggle__options__tab {
  display: flex;
  flex-flow: row;
  align-items: center;

  padding: 7px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #e7f5fd;
    border-radius: 4px;

    .customDropdown__toggle__options__tab__txt {
      color: #0065a4 !important;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.customDropdownContainer__label {
  margin-bottom: 6px;
}

.customDropdown__toggle__optionTxt {
  // margin-left: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3f4254;

  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Ensures overflow is hidden */
  text-overflow: ellipsis; /* Adds an ellipsis to overflowed text */
  flex-grow: 1; /* Allows the text to fill the space available */
}

.customDropdown__toggle__options__tab__txt {
  font-size: 16px;
  font-weight: 500;
  color: #3f4254;
}

.customDropdown--react-select {
  width: 100%;
}
