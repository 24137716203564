.super-admin-elector-list {
  padding-left: 265px;
  padding-top: 90px;
  padding-right: 45px;
  @media only screen and (max-width: 1370px) {
    margin-top: 60px;
  }
}

.SuperAdmin__ElectorsList {
  padding-left: 290px;
  padding-top: 115px;
  padding-right: 50px;

  @media only screen and (max-width: 1370px) {
    margin-top: 60px;
  }
}

.super-admin-elector-list__header {
  display: flex;
  flex-flow: row;
  margin-right: 95px;
}

.adminUsers__eventComponent__btn {
  // width: 211px;
  height: 46px;
  border: 2px solid #3f4254;
  border-radius: 6px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.adminUsers__eventComponent__btn__txt {
  font-size: 15px;
  color: #3f4254;
  font-weight: 600;
}

.adminUsers__eventComponent__btnOuter {
  position: absolute;
  right: 55px;
}
