@import '../../../../../../theme/variables.ems';

@import '../../../../../styles/variables';

.electorsListTable--noResult {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.electorsListTable--noResult__icon {
  font-size: 100px;
}

.electorsListTable--noResult__txt {
  font-size: 18px;
  margin-top: 20px;
}

.electorsListTable--noResult__searchLink {
  font-size: 16px;
  text-decoration: underline;
  color: $emsPrimaryColor;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.electorsListTable__rowValue__voted,
.electorsListTable__rowValue__channel {
  font-weight: 600;
  font-size: 12px;
  width: fit-content !important;
  height: 26px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 11.5px;
  padding-right: 11.5px;
  justify-content: center;
  font-weight: 700;
  text-transform: capitalize;
}

.electorsListTable__rowValue__voted--na {
  color: #6c7289;
  background-color: #f2f3f7;
}

.electorsListTable__rowValue__voted--declined {
  color: #ffff;
  background-color: $errorColor;
}
.electorsListTable__rowValue__voted--disabled {
  color: #ffff;
  background-color: $errorColor;
}

.importAddrList__statusLabel--notused {
  color: #f1bc00;
  background-color: #fef2c7;
}

.electorsListTable__rowValue__voted--approved {
  color: #ffffff;
  background-color: #08b055;
}

.electorsListTable__rowValue__voted--pending {
  // color: #f1bc00;
  // background-color: #fef2c7;
  color: #fff;
  background-color: $warningColor;
}

.electorsListTable__rowValue__voted--onboarded {
  color: #3699ff;
  background-color: #f5f8fa;
}

.electorsListTable__rowValue__voted--voted {
  color: #08b055;
  background-color: #e8fff3;
}

.electorsListTable__rowValue__channel--inPerson {
  color: #f1416c;
  background-color: #fff5f8;
}

.electorsListTable__rowValue__channel--online {
  color: #3699ff;
  background-color: #f5f8fa;
}

.electorsListTable__rowValue__channel--mail {
  color: #ffa136;
  background-color: #fff9f2;
}

.electorsListTable__rowValue__channel--phone {
  color: #c6ff36;
  background-color: #f9ffeb;
}
