.SelectField {
  height: 50px;

  background: #f5f8fa;
  padding-left: 20px;

  border-radius: 8px;
  border: none;
  width: 100%;

  font-size: 14px;
  font-weight: 500;
  color: #a1a5b7;

  &:focus {
    outline: none;
  }
}

.SelectFieldSmall {
  height: 40px;
  padding-left: 10px;
  background: #f5f8fa;

  border-radius: 8px;
  border: none;
  width: 100px;

  font-size: 14px;
  font-weight: 600;
  color: #a1a5b7;

  &:focus {
    outline: none;
  }
}

.SelectField__voter {
  height: 45px;

  // padding: 20px;
  background: #fff;
  padding-left: 15px;
  border-radius: 6px;
  border: 1px solid #000;
  min-width: 174px;
  width: 100%;

  color: #3f4254;

  &:focus {
    outline: none;
    border: 2px solid #000;
    box-shadow: 0px 0px 7px 3px rgba(54, 153, 255, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.SelectField__voterAdmin {
  height: 35px;

  display: flex;
  flex-flow: row;

  background: #fff;

  border-radius: 6px;
  border: 1px solid #000;
  width: 100%;

  color: #3f4254;

  &:focus {
    outline: none;
    border: 2px solid #000;
    box-shadow: 0px 0px 7px 3px rgba(54, 153, 255, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.SelectField__voterAdminInner {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: 15px;
}
