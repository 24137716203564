@import '../../../../styles/variables.scss';

.SuperAdmin__MyProfile {
  @media only screen and (max-width: 1370px) {
    margin-top: 130px !important;
  }
}

.myProfiletM__title {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $primaryColor;
    margin-top: 125px;
    text-align: center;
    margin-bottom: -100px;
  }
}

.MEXICO__MyProfile {
  margin-left: 240px;
  margin-top: 65px;

  background-color: #f2f3f7;

  @media only screen and (max-width: 1370px) {
    margin-top: 130px !important;
  }

  @media only screen and (max-width: 820px) {
    margin-left: 15px;
    margin-top: 90px !important;
    margin-right: 15px;
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 15px;
    margin-top: 0px !important;
    margin-right: 15px;
    margin-bottom: 100px;
  }
}

.myProfile__body {
  margin-left: 25px;
  margin-top: 26px;
  margin-right: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 120px;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
  }
}

.myProfile__body__details {
  border-radius: 8px;
  height: 787px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.myProfile__body__details__usernameSection,
.myProfile__body__details__passwordSection,
.myProfile__body__details__authMethodSection {
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid #e1e1ee;

  padding: 30px 33px 25px 33px;
}

.myProfile__body__details__txt__label {
  font-weight: 400;
  font-size: 16px;
  color: #3f4254;
  margin-bottom: 6px;
}

.myProfile__body__details__txt__value {
  font-weight: 600;
  font-size: 18px;
  color: #181c32;
}

.myProfile__body__details__infoIcon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.myProfile__body__details__txtIconWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.myProfile__body__details__actionLink {
  font-weight: 500;
  font-size: 16px;
  color: $primaryColor;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}

.myProfile__body__details__actionLinkWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 452px;
}

.myProfile__body__details__qrCodeSection {
  padding: 30px 33px 25px 33px;
  display: flex;
  flex-flow: column;
}

.myProfile__body__details__qrCode__img {
  width: 161px;
  height: 161px;
  margin-bottom: 23px;
}

.myProfile__body__details__qrCode__label {
  margin-bottom: 5px;
}

.myProfile__body__details__qrCode__txtWrap {
  display: flex;
  flex-flow: column;
}

.myProfile__body__details__qrCode__txtBanner {
  border-radius: 12px;
  background-color: #f7f7f7;
  padding: 16px 23px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
}

.myProfile__body__details__qrCode__txtBanner__icon {
  margin-right: 21px;
  @media only screen and (max-width: 768px) {
    width: 40px;
    margin-bottom: 15px;
    margin-right: 0px;
  }
}
