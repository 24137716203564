@import '../theme/variables.ems';

.ModalHeaderWithLabel {
  display: flex;
  padding: 21px 25px;
  border-bottom: 1px solid #eef2f5;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.modalHeaderWithLabel__label {
  font-size: 22px;
  font-weight: 500;
  color: #000;
}
