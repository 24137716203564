@import '../../../../../../theme/variables.ems';

.filterModal__body {
  display: flex;
  flex-flow: row;
  // justify-content: center;
  overflow-y: visible !important;
  padding: 30px;
}

.filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  overflow-y: visible !important;

}


.filterModal__cancelBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: $emsPrimaryColor;
  border: 1px solid $emsPrimaryColor;
  background-color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}



.filterModal__body__dropdownCol--region,
.filterModal__body__dropdownCol--group,
.filterModal__body__dropdownCol--classified {
  margin-right: 25px;
}

.filterModal__body__dropdownCol--region,
.filterModal__body__dropdownCol--group,
.filterModal__body__dropdownCol--classified,
.filterModal__body__dropdownCol--employer {
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
}



.userfilterModal__dropdown__container {
  display: flex;
  width: 50%;
  margin-top: 10px;
}