.event-status {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.event-status__voted-status {
  margin-left: 10px;
}
