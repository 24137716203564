@import '../../../../styles/variables.scss';

.MEXICO__Dashboard,
.MEXICO__Dashboard--spanish {
  padding-bottom: 198px;

  margin-left: 240px;
  margin-top: 65px;
  overflow-x: hidden;

  background-color: #f2f3f7;

  @media only screen and (max-width: 1370px) {
    margin-top: 130px !important;
  }

  @media only screen and (max-width: 820px) {
    padding-bottom: 100px;
    margin-left: 0px;
    margin-top: 90px !important;
    margin-right: 0px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 0px !important;
  }
}

.MEXICO__Dashboard--spanish {
  @media only screen and (max-width: 965px) {
    padding-bottom: 100px;
    margin-left: 0px !important;
    margin-top: 90px !important;
    margin-right: 0px !important;
  }
}

.dashboard__body {
  margin-left: 25px;
  margin-top: 26px;
  margin-right: 40px;

  display: flex;
  flex-flow: row wrap;

  @media only screen and (max-width: 768px) {
    margin-right: 10px;

    margin-top: 120px;
    margin-left: 10px;
  }
}

.dashboard__body__topBanner {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  border-radius: 8px;
  height: 244px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  padding: 25px 30px 29px 30px;

  display: flex;
  flex-flow: row;
}

.dashboard__body__topBanner__label {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}

.dashboard__body__topBanner__voteStatusWrap {
  margin-top: 11px;
}

.dashboard__body__topBanner__voteStatus__label {
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.dashboard__body__topBanner__voteStatus__divider {
  color: #a1a5b7;
  font-weight: 400;
  font-size: 18px;
  margin-left: 48px;
  margin-right: 28px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.dashboard__body__topBanner__infoCardsContainer {
  display: flex;
  flex-flow: row;

  margin-top: 31px;
}

.dashboard__body__topBanner__closingAtCard,
.dashboard__body__topBanner__votingStatusCard {
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  min-width: 295px;
  max-width: auto;
  height: 110px;
  border-radius: 8px;
  border: 1px solid #e1e1ee;
  display: flex;
  flex-flow: column;

  padding: 15px 25px 16px 31px;
}

.dashboard__body__topBanner__closingAtCard {
  @media only screen and (max-width: 768px) {
    height: fit-content;
  }
}

.dashboard__body__topBanner__votingStatusCard {
  margin-left: 33px;
}

.dashboard__body__topBanner__closingAtCard__label,
.dashboard__body__topBanner__votingStatusCard__label {
  font-weight: 400;
  font-size: 16px;
  color: #6c7289;
  margin-bottom: 9px;
}

.dashboard__body__topBanner__closingAtCard__value {
  font-weight: 600;
  font-size: 18px;
  color: #181c32;
}

.dashboard__body__topBanner__votingStatusCard__value {
  font-weight: 500;
  font-size: 18px;
  color: $primaryColor;
}

.dashboard__body__topBanner__voteNowBtn {
  margin-top: 39px;
  width: 159px;
  height: 49px;
  background-color: $primaryColor;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.fw-400 {
  font-weight: 400;
}

.dashboard__disclaimerBanner {
  background-color: #f4eaf4;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 25px;
  height: 82px;
  border-radius: 12px;
  padding-left: 22px;
  padding-right: 15px;
  margin-right: 47px;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    height: auto;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 22px;
    margin-right: 15px;
    margin-left: 15px;
  }
}

.dashboard__disclaimerBanner__txt {
  font-size: 16px;
  color: #5e6278;
  font-weight: 400;
  margin-left: 18px;
}

.dashboard__votingStatusWrap {
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column !important;
    align-items: flex-start !important;
  }
}

.dashboard__eventVoteCard,
.dashboard__eventVoteCard--spanish {
  width: 100%;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    align-items: center;
    margin-top: 120px;
  }
}

.dashboard__eventVoteCard--spanish {
  @media only screen and (max-width: 768px) {
    margin-top: 35px;
  }
}

.dashboard__eventVoteCard__title {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: $primaryColor;
    margin-bottom: -7px;
  }
}
