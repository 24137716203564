.auth-app-modal {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;

  @media only screen and (max-width: 768px) {
    max-height: 700px;
  }
}

.auth-app-modal__body {
  padding: 33px 37px;
}

.auth-app-modal__body__heading {
  display: flex;
  flex-flow: column;
  margin-bottom: 5px;
}

.auth-app-modal__body__heading__title {
  font-size: 18px;
  font-weight: 500;
}

.auth-app-modal__body__heading__subtitle {
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}

.auth-app-modal__body__examples {
  display: flex;
  flex-flow: column;
  margin-top: 7px;
}

.auth-app-modal__body__examples__title {
  font-size: 16px;
  font-weight: 600;
}

.auth-app-modal__body__examples__subtitle {
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}

.auth-app-modal__body__examples--other-apps {
  margin-bottom: 20px;
}

.auth-app-modal__body__bottom-note {
  font-size: 16px;
  font-weight: 400;
}
