@import '../../../theme/variables.ems';

.CustomBtn,
.CustomBtn--cancel,
.CustomBtn--primary,
.CustomBtn--primaryGrey {
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;

  border-radius: 6px;

  font-weight: 600;
  font-size: 16px;

  color: #fff;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.CustomBtn {
  background-color: $emsPrimaryColor;
}

.CustomBtn--cancel {
  background-color: #a1a5b7;
  color: #fff;
  margin-right: 25px;
}

.CustomBtn--primary {
  background-color: #fff;
  color: $emsPrimaryColor;

  border: 1px solid $emsPrimaryColor;
}

.CustomBtn--primaryGrey {
  background-color: #fff;
  color: #6c7289;
  // margin-right: 25px;
  border: 1px solid #6c7289;
}
