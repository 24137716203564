// TODO: move these styles

.addressList__districtDropdown,
.adminDropdown--languages {
  width: fit-content;
  height: 35px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  background: #fff;

  // border-radius: 6px;
  border: 1px solid #000;
  border: none;
  color: #3f4254;


  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:focus {
    outline: none;

    box-shadow: 0px 0px 7px 3px rgba(54, 153, 255, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}


.adminDropdown--languages {
  @media only screen and (max-height: 450px) {
  }
}

.addressList__districtDropdown__options,
.adminDropdown__options--languages {
  width: 357px;
  position: relative;
  max-height: 668px;
  overflow-y: scroll;
}

.adminDropdown__options--languages {
  @media only screen and (max-height: 450px) {
    position: relative !important;
  }
}

.navbar__dropdown {
  z-index: 1;
  position: absolute;

  border-radius: 4px;

  background: #ffffff;
  box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);

  li {
    list-style: none;

    /* pobup-menu-text-14 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #3f4254;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
