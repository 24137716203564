@import '../../../common.scss';
@import '../../../../../../../app/styles/variables.scss';

.EventVoteCard {
  margin-left: 25px;
  margin-top: 30px;
  margin-right: 47px !important;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 8px;

  @media only screen and (max-width: 912px) {
    // width: 90%;
    margin-left: 16px;
    margin-right: 16px !important;
  }
}

.eventVoteCard__body {
  display: flex;
  flex-flow: column;
  //   justify-content: space-between;

  height: 323px;
  padding: 25px 35px 29px 30px;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    height: 100%;
    width: 100%;
  }
}

.eventVoteCard__body__iconsWrap {
  display: flex;
  flex-flow: column;
  margin-right: 35px;
}

.eventVoteCard__body__row1--calendar,
.eventVoteCard__body__row2__icon--clock {
  width: 55px;
  height: 55px;
  margin-right: 35px;
}

.eventVoteCard__body__row2__icon--clock {
  @media only screen and (max-width: 1024px) {
    margin-bottom: 21px;
  }
}

.eventVoteCard__body__row1__content {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.eventVoteCard__body__row1__content__titleWrap {
  width: 100%;
  display: flex;
  flex-flow: row;

  justify-content: space-between;
}

.eventVoteCard__body__row1__content__votIsOpenWrap {
  margin-bottom: 25px;

  @media only screen and (max-width: 1024px) {
    @include common__flex__column;
    margin-top: 16px;
  }
}

.eventVoteCard__body__row1 {
  @include common__flex__row;
  margin-bottom: 21px;

  @media only screen and (max-width: 1024px) {
    @include common__flex__column;
  }
}

.eventVoteCard__body__row1__content__icon--voting {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.eventVoteCard__body__row2 {
  @include common__flex__row;
}

.eventVoteCard__body__row2__content__cards {
  @include common__flex__row;
  @media only screen and (max-width: 1024px) {
    @include common__flex__column;
  }
}

.eventVoteCard__body__row2__content__linkWrap {
  margin-top: 25px;
  @include common__flex__row__center;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.eventVoteCard__body__row2__content__linkWrap--1 {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.eventVoteCard__body__row2__content__linkWrap__icon--ballot,
.eventVoteCard__body__row2__content__linkWrap__icon--reciept {
  margin-right: 15px;
}

.eventVoteCard__body__row2__content__linkWrap__txt {
  font-size: 16px;
  color: #6c7289;
  font-weight: 500;
}

.eventVoteCard__body__row2__content__linkWrap__icon--reciept {
  margin-left: 25px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0px;
  }
}

.eventVoteCard__footer {
  @include common__flex__row__center;

  justify-content: space-between;
  width: 100%;
  height: 100px;
  border-top: 1px solid #e1e1ee;
  padding-left: 115px;

  @media only screen and (max-width: 1024px) {
    padding-left: 0px;
    height: fit-content;
    @include common__flex__column;
  }
}

.eventVoteCard__footer__btnWrap {
  @media only screen and (max-width: 1024px) {
    padding-left: 23px;
    padding-right: 23px;
    width: 100%;
  }
}

.eventVoteCard__footer__noBtn {
  @media only screen and (max-width: 1024px) {
    margin-bottom: 35px;
  }
}

.eventVoteCard__footer__btn--voteNow {
  margin-top: 0px;
  margin-right: 27px;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (max-width: 1024px) {
    margin-right: 0px;
    margin-top: 35px;
    margin-bottom: 21px;
    width: 100%;
  }
}

.eventVoteCard__footer__timeCardWrap {
  @include common__flex__column;
}

.eventVoteCard__footer__timeCard__smallLabel {
  font-size: 9px;
  color: #6c7289;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.68px;
}

.eventVoteCard__footer__title,
.eventVoteCard__footer__timeCard__value {
  font-size: 18px;
  font-weight: 700;
}

.eventVoteCard__footer__timeCard__value {
  color: $primaryColor;
}

.eventVoteCard__footer__title {
  margin-right: 13px;
  text-align: center;
  font-weight: 600;
  &.eventVoteCard__footer__title-vertical{
    margin-bottom: 18px;
  }

  color: #3f4254;
 
  @media only screen and (max-width: 1024px) {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 21px;
    margin-top: 37px;
  }
}

.eventVoteCard__footer__timeCard {
  width: 54.32px;
  height: 54.32px;
  background-color: #fff;

  @include common__flex__fullCenter;

  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.eventVoteCard__footer__timeCard__value {
}

.eventVoteCard__footer__timeDivider {
  font-size: 22px;
  color: #000;
  margin-left: 13px;
  margin-right: 13px;
  font-weight: 500;
  margin-top: 10px;
}

.eventVoteCard__body__row2 {
  @media only screen and (max-width: 1024px) {
    @include common__flex__column;
  }
}

.dashboard__body__topBanner__votingStatusCard {
  @media only screen and (max-width: 1024px) {
    margin-top: 25px;
    margin-left: 0px;
  }
}

.eventVoteCard__footer__contentWrap {
  @include common__flex__row__center;

  &.eventVoteCard__footer__contentWrap-vertical{
    @include common__flex__column;

  }
  @media only screen and (max-width: 1024px) {
    @include common__flex__column;
  }
}

.eventVoteCard__body__row2__content__linkWrap--mobile {
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.eventVoteCard__body__row1__content__icon--votingMobile {
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
    width: 116px;
    margin-bottom: 30px;
  }
}
