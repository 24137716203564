// Margin-right classes
@mixin mr-classes($max-value) {
  @for $i from 0 through $max-value {
    $class-name: unquote('mr-' + $i);
    .#{$class-name} {
      margin-right: $i + px !important;
    }
  }
}

// Margin-left classes
@mixin ml-classes($max-value) {
  @for $i from 0 through $max-value {
    $class-name: unquote('ml-' + $i);
    .#{$class-name} {
      margin-left: $i + px !important;
    }
  }
}

// Margin-top classes
@mixin mt-classes($max-value) {
  @for $i from 0 through $max-value {
    $class-name: unquote('mt-' + $i);
    .#{$class-name} {
      margin-top: $i + px !important;
    }
  }
}

// Margin-bottom classes
@mixin mb-classes($max-value) {
  @for $i from 0 through $max-value {
    $class-name: unquote('mb-' + $i);
    .#{$class-name} {
      margin-bottom: $i + px !important;
    }
  }
}

// Generate margin-right classes up to 50
@include mr-classes(100);

// Generate margin-left classes up to 50
@include ml-classes(100);

// Generate margin-top classes up to 50
@include mt-classes(100);

// Generate margin-bottom classes up to 50
@include mb-classes(100);
