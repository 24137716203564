@import './_accessibility.scss';
@import './_shared.scss';
@import './_variables.scss';
@import './_flex.scss';
@import './_margin.scss';
@import '../components/index.scss';
@import './_table.scss';

// pages
// /pages/private
@import '../pages/private/index.scss';

// /pages/public
@import '../pages/public/index.scss';

// components
@import '../components/index.scss';

.grecaptcha-badge {
  display: none !important;
}
