.a-event {
  width: 100%;
  margin-right: 0px !important;
  display: flex;
  justify-content: flex-start;

  &__body {
    padding: 20px;
    padding-right: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__body__tc-n-icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__body__icon {
    width: 36px;
  }
}

// title container
.a-event__body__tc {
  display: flex;
  flex-flow: column;
  margin-left: 25px;

  &__title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  &__title--admin {
    max-width: 100% !important;
  }
}
