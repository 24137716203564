.BackBtn {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.backBtn__label {
  font-size: 16px;
  font-weight: 500;
}
