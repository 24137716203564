@import '../../../../styles/variables.scss';

.SuperAdmin__Events {
  background-color: #f2f3f7;
  height: 100vh;
  width: 100%;
  max-height: fit-content;

  @media only screen and (max-width: 1370px) {
    margin-top: 80px;
  }
}

.superAdmin__events__header {
  padding-left: 265px;
  padding-top: 90px;
  padding-right: 50px;
}

.superAdmin__events__content {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.superAdmin__events__content__noEvent {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  // margin-top: 25px;
  width: 566px;
  height: 229px;
  background-color: #fff;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.superAdmin__events__content__noEvent__icon {
  width: 69.72px;
  height: 70px;
}

.superAdmin__events__content__noEvent__txt {
  font-weight: 400;
  font-size: 16px;
  margin-top: 37px;
}

.superAdmin__events__header__txt {
  font-weight: 500;
  color: $primaryColor;
  font-size: 24px;
}

.superAdmin__events__list {
  display: flex;
  flex-flow: column;
  padding-left: 265px;
  margin-top: 32px;
  margin-right: 45px;
}

.superAdmin__events__list__card {
  height: 86px;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 23px;
  padding-right: 44px;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.superAdmin__events__list__card__titleWrap {
  display: flex;
  align-items: center;
}

.superAdmin__events__list__card__icon {
  width: 36px;
  height: 36px;
}

.superAdmin__events__list__card__txt {
  font-size: 22px;
  font-weight: 600;
  margin-left: 18px;
}

.superAdmin__events__list__card__link {
  font-weight: 500;
  color: $primaryColor;
  font-size: 16px;
}

.superAdmin__eventDetail {
  margin-right: 135px;
  margin-left: 265px;
  padding-bottom: 100px;
}

.superAdmin__eventDetail__content {
  padding-top: 28px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.superAdmin__eventDetail__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 17px 24px 37px 24px;
  margin-top: 13px;
  border-radius: 8px;
}

.adminDash__successVotersCard--eventDetail {
  margin-right: 0px;
}

.superAdmin__events__header__tabs {
  display: flex;
  align-items: center;
}

.superAdmin__events__header__tabs__icons {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 28px;
  margin-top: 3px;
}
.superAdmin__events__header__tabs--calendar {
  margin-right: 16px;
}
.superAdmin__events__header__tabs--calendar,
.superAdmin__events__header__tabs--list {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.superAdmin__eventDetail__content {
  padding-top: 28px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
