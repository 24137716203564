@import '../../../private/voter/common.scss';

.AdminSidebarMobile {
  width: 299px;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
}

.adminSidebarM__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 16px 15px 15px;

  border-bottom: 1px solid #ececec;
  margin-bottom: 27px;
}

.adminSidebarM__header__logo {
  width: 169px;
}

.adminSidebarM__header__xIcon {
  width: 30px;
  height: 30px;
}

.adminSidebarM__body__title {
  margin-left: 29px;
  font-size: 16px;
}

.adminSidebarM__body__tabContainer {
  margin-top: 28px;
}

.adminSidebarM__footer {
  border-top: 1px solid #ececec;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-bottom: 135px;

  margin-top: 23px;
}

.adminSidebarM__footer__logoutBtn {
  margin-top: 23px;
  color: $INE__pink;
  font-size: 15px;
  font-weight: 500;
  border: none;
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  height: 42px;
  border: 1px solid $INE__pink;
}

.adminSidebarM__bodyFooterContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  overflow-y: scroll;
}

.adminSidebarM__currentTimeTxt {
  font-size: 16px;
  display: flex;
  flex-flow: column;
  // align-items: center;
  margin-left: 29px;
  //   margin-right: 57px;
}

@media only screen and (max-width: 768px) {
  .AdminSidebarMobile__outer {
    display: block;
  }
}

.adminSidebarM__adminDropdownWrapper--languages {
  margin-left: 20px;
  margin-top: 30px;
  height: 100% !important;

  @media only screen and (max-height: 450px) {
    margin-bottom: 100px;
  }
}

.adminDropdown__container--languages {
  @media only screen and (max-height: 450px) {
    height: 100%;
  }
}
